.tableSearch {
  display: flex;
  align-items: center;
  position: relative;
  .searchIcon {
    position: absolute;
    left: 16px;
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: 0;
    padding: 12px 16px 12px 48px;
    border-bottom: 1px solid $color-gray-1;
    background: transparent;
  }
}

.tablePaper {
  .paperHeader {
    padding: 10px;
  }
  .paperBody {
    padding: 0;
    .tableWrapper {
      background: none;
      box-shadow: none;
      border-radius: 0;
      border: 0;
    }
  }
}

.tableWrapper {
  background: $color-white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid $color-gray-1;
  .table {
    .tableHolder {
      .tableBody {
        .tableRow {
          &:nth-child(even) {
            background: $color-gray-2;
          }
        }
        &.nested {
          .groupWithChildren {
            &:nth-child(even) {
              background: $color-gray-2;
            }
            .nestedTable {
              .tableRow {
                border-bottom: 1px solid $color-white;
                &.tableHeader {
                  background: #f4f4f4;
                }
                background: #f4f4f4;
              }
            }
          }
        }
      }
      .tableRow {
        display: grid;
        align-items: center;
        grid-gap: 10px;
        padding: 0 10px;
        border-bottom: 1px solid $color-gray-1;
        &.totalRow {
          .tableCell {
            font-weight: 600;
          }
        }
        &.tableHeader {
          .tableCell {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: $color-text-3;
            span {
              cursor: pointer;
            }
          }
        }
        &.successRow {
          background: rgba($color: $color-success, $alpha: 0.09) !important;
        }
        &.dangerRow {
          background: rgba($color: $color-danger, $alpha: 0.09) !important;
        }
        .tableCell {
          padding: 10px 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a {
            &.link {
              text-decoration-line: underline;
            }
          }
          .linked {
            text-decoration: underline;
            cursor: pointer;
          }
          &.danger {
            span {
              color: $color-danger;
            }
          }
          &.successCell {
            color: $color-success;
          }
          &.dangerCell {
            color: $color-danger;
          }
          .tableDownArrow {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-gray-1;
            background: $color-white;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              background: $color-gray-2;
            }
            &.active {
              svg {
                transform: rotate(180deg);
              }
            }
            svg {
              width: 18px;
              height: 18px;
              transition: all 0.2s ease-in-out;
            }
          }
          &.regular {
            position: relative;
            width: 100%;
            overflow: unset;
            ul {
              position: absolute;
              top: 100%;
              right: 10px;
              z-index: 2;
              background: $color-white;
              border: 1px solid $color-gray-1;
              box-shadow: 0px 12px 16px rgba(0, 0, 0, 0e8);
              border-radius: 8px;
              overflow: hidden;
              min-width: 200px;
              li {
                width: 100%;
                padding: 12px 16px;
                transition: all 0.2s ease-in-out;
                border-bottom: 1px solid $color-gray-1;
                font-size: 14px;
                line-height: 20px;
                cursor: pointer;
                &.danger {
                  color: $color-danger;
                }
                &:last-child {
                  border-bottom: 0;
                }
                &:hover {
                  background: $color-gray-3;
                }
                a {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
          &.indexOf {
            &::after {
              content: '.';
            }
          }
          .moreBtn {
            width: 20px;
            height: 20px;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .noSearchResults {
        text-align: center;
        padding: 50px 0;
        font-weight: 500;
      }
    }
  }
  .tableLegend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .tablePerPage {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    .tablePagination {
      margin-left: auto;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.tableUsers {
  .tableRow {
    grid-template-columns: 0.5fr 1.5fr 1fr 1fr 0.5fr 100px 100px;

    .tableCell {
      &.delete {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          all: unset;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.tableOrganizations {
  .tableRow {
    grid-template-columns: 0.5fr 1.5fr 1fr 1fr 0.5fr;
  }
}

.tableOrders {
  .tableRow {
    grid-template-columns: 90px 100px 2fr 2fr 2fr 100px 1fr;
  }
}

.paper {
  .paperHeader {
    .left {
      display: flex;
      align-items: center;
      grid-gap: 16px;
      .tableSearch {
        input {
          width: 350px;
          border: 1px solid $color-gray-1;
          border-radius: 8px;
          font-size: 16px;
          padding: 12px 16px 12px 42px;
          transition: all 0.2s ease-in-out;
          &:focus {
            border-color: $color-success-3;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      grid-gap: 16px;
    }
  }
}

.tablePayment {
  .tableRow {
    grid-template-columns: repeat(5, 1fr);
  }
}

.tableOrderItems {
  .tableRow {
    grid-template-columns: 30px 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 50px;
    .tableCell {
      &:last-child {
        svg {
          cursor: pointer;
        }
      }
    }
  }
}

.tableOrgOffices {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 25px;

    .hoverCell {
      position: relative;
      overflow: visible !important;
      cursor: pointer;

      .officeUsersTooltip {
        position: absolute;
        right: 100%;
        background-color: $color-white;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.09);
        border-radius: 8px;

        .title {
          font-size: 16px;
          background-color: $color-gray-2;
          padding: 14px;
        }
        .list {
          padding: 12px;
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
      }
    }
  }
}

.tableOrgUsers {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 150px 20px;
  }
}

.tableOrgUserInvitations {
  .tableRow {
    grid-template-columns: 1.5fr 1.5fr 1fr 150px 20px;
  }
}

.tableOrgOrders {
  .tableRow {
    grid-template-columns: repeat(8, 1fr);
  }
}

.tableOrgDraftOrders {
  .tableRow {
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 20px;
  }
}

.tableOrgOrdersItems {
  .tableRow {
    grid-template-columns: 30px 2fr 1fr 1fr 150px;
  }
}

.tableOfficeUsers {
  .tableRow {
    grid-template-columns: 70px 1fr 1fr 1fr;
  }
}

.tableOrgSuppliers {
  .tableRow {
    grid-template-columns: 150px 1fr 1fr 1fr 1fr;
    .tableCell {
      .supplierLogo {
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.tableOrgPaymentMethods {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 20px;
  }
}

.tableOrderSnapshot {
  .tableRow {
    grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 25px;
  }
}

.tableOrgFormularyItems {
  .tableRow {
    grid-template-columns: 30px 2fr 1fr 1fr 1fr 1fr 50px;
  }
}

.tablePurchaseOrders {
  .tableRow {
    grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20px 25px;
  }
}

.tableProvidersInvoices {
  .tableRow {
    grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 150px 20px 25px;
    .tableCell {
      .attachPdfDropdown {
        width: 100%;
        height: 30px;
        margin: 0;

        h2 {
          font-weight: 500;
          font-size: 10px;
          margin: 0;
        }
        h3 {
          font-size: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          span {
            font-size: 0;
            display: none;
            &.mobText {
              display: block;
              font-size: 10px;
            }
          }
        }
        p,
        svg,
        .errorFieldMsg {
          display: none;
        }
      }
    }
  }
}

.tableFaliamInvoices {
  .tableRow {
    grid-template-columns: 30px 100px 100px 1fr 1fr 100px 100px 0.5fr 0.5fr 0.5fr 0.5fr 85px 150px 20px 25px;
    .tableCell {
      .attachPdfDropdown {
        width: 100%;
        height: 30px;
        margin: 0;

        h2 {
          font-weight: 500;
          font-size: 10px;
          margin: 0;
        }
        h3 {
          font-size: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          span {
            font-size: 0;
            display: none;
            &.mobText {
              display: block;
              font-size: 10px;
            }
          }
        }
        p,
        svg,
        .errorFieldMsg {
          display: none;
        }
      }

      li {
        &.disabled {
          pointer-events: none !important;
          cursor: not-allowed !important;
        }
      }
    }
  }
}

.tableCreatorInfo {
  .tableRow {
    grid-template-columns: repeat(7, 1fr);
  }
}

.tableOrderShipments {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 25px 25px;
  }
}

.tableOrderShipmentItems {
  .tableRow {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 25px;
  }
}

.tableNestedTrackingNumbers {
  .tableRow {
    grid-template-columns: 100px 1fr 1fr 1fr 1fr 40px;
  }
}

.tableOrderSummary {
  .table {
    .tableHolder {
      .tableRow {
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  .tableRow {
    grid-template-columns: 100px 100px 2fr 1fr 1fr 1fr 100px 130px 160px 140px;
    .danger {
      span {
        display: flex;
        align-items: center;
      }
    }

    .centered {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .orderStatus {
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  }
  .tableLegend {
    display: none;
  }
}

.tableCsOverride {
  .tableRow {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20px;
    .withIcon {
      svg {
        width: 24px;
        height: 24px;
        &.s {
          path {
            fill: $color-success;
          }
        }
        &.d {
          path {
            fill: $color-danger;
          }
        }
      }
    }
  }
}

.tableCreateBackOrder {
  .tableRow {
    grid-template-columns: 50px 50px 2fr 1fr 1fr 1fr 1fr 1fr 117px 1fr;
  }
}

.tableOrgInvoices {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 120px;
  }
}

.tableOrgGpos {
  .tableRow {
    grid-template-columns: 300px 200px;
  }
}

.tableBackOrders {
  .tableRow {
    grid-template-columns: 30px 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20px;
  }
}

.tableShipmentHistory {
  .tableRow {
    grid-template-columns: 1fr 1fr 2fr 1fr 3fr;
  }
}

.tableBudgets {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 20px;
  }
}

.tableCOitems {
  .tableRow {
    grid-template-columns: 50px 2fr 1fr 1fr 1fr 1.5fr 1fr 1fr 100px;
    .tableCell {
      &.canUpdatePrice {
        display: flex;
        align-items: center;
        grid-gap: 12px;
      }
      .counter {
        grid-template-columns: 30px 1fr 30px;
        grid-template-rows: 28px;
        input {
          font-size: 14px;
        }
      }
    }
  }
}

.tableCreateShipments {
  .tableRow {
    grid-template-columns: 50px 2fr 1fr 1fr 1fr 1.5fr 1fr 1fr 100px;
    .tableCell {
      .counter {
        grid-template-columns: 30px 1fr 30px;
        grid-template-rows: 28px;
        input {
          font-size: 14px;
        }
      }
    }
  }
}

.tableRequiresOpsAction {
  .tableRow {
    grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 25px;
  }
}

.tableRequiresOpsActionBoxes {
  .tableRow {
    background: #eaeaea !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
}

.nestedTable {
  &.tableRequiresOpsActionItems {
    .tableRow {
      grid-template-columns: 60px 1.5fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 25px;
    }
  }

  &.tablePurchaseOrdersItems {
    .tableRow {
      grid-template-columns: 60px 110px 60px 200px 1fr;
    }
  }
  &.tableFaliamInvoicesOrdersItems {
    .tableRow {
      grid-template-columns: 60px 150px 100px 100px 70px 100px 1fr;
    }
  }
  &.tableProvidersInvoicesItems {
    .tableRow {
      grid-template-columns: 60px 100px 100px 70px 100px 200px 1fr;
    }
  }
  &.nestedTableOrderSnapshot {
    .tableRow {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr;
    }
  }
  &.nestedTableProvierInvoiceModal {
    .tableRow {
      grid-template-columns: 60px 70px 200px 120px 120px 120px;
      &.fullWidthRow {
        display: block;
        span {
          display: flex;
          align-items: center;
          grid-gap: 4px;
          width: fit-content;
          cursor: pointer;
        }
      }
      .backOrderCell {
        span {
          display: flex;
          align-items: center;
          grid-gap: 4px;
          cursor: pointer;
          width: fit-content;
        }
      }
    }
  }
}

.tableProvierInvoiceModal {
  .tableRow {
    grid-template-columns: 60px 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 90px 90px 25px;
    .editBtn {
      width: 90px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.selectAllCheckbox {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin-bottom: 16px;
}

.tableAllGpos {
  .tableRow {
    grid-template-columns: 100px 1fr 1fr;
    .tableCell {
      .imgBox {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.jobsTable {
  margin-bottom: 20px;
}

.tableJobs {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 150px;

    .tableCell {
      &.clickable {
        cursor: pointer;
      }
    }
  }
}

.tableAutoCartQueueBySupplier {
  .tableRow {
    grid-template-columns: 300px 200px 1fr;

    .relativeCont {
      position: relative;

      .listWrapper {
        position: absolute;
        width: fit-content;
        padding: 16px;
        z-index: 9999999;
        max-width: 500px;

        p {
          margin-bottom: 12px;
        }

        .pendingList {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;

          a {
            background-color: $color-success-1;
            padding: 6px;
            margin-bottom: 6px;
            border-radius: 4px;
          }
        }
      }
    }

    .withButton {
      span {
        display: flex;
        align-items: center;
        gap: 8px;

        button {
          padding: 2px 5px;
          font-size: 12px;
          width: fit-content;
        }
      }
    }
  }
}

.tableAdminUsers {
  .tableRow {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 20px;
  }
}